import { template as template_0bbf30b8960e445cb8d9e22cf6ff0ed0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0bbf30b8960e445cb8d9e22cf6ff0ed0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
